import AppIcon from "static/images/finkraftIcon.svg";
import AppLogo from "static/images/FinLogo.png";
import AppLogoShort from "static/images/finkarftLogoShort.png";

import "./PrimaryNav.scss";
import {
  SettingOutlined,
  ReconciliationOutlined,
  LockFilled,
  ScanOutlined,
  MailOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Avatar, Divider, Drawer, Dropdown, MenuProps } from "antd";
import Typography from "../Typography";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import SubMenu from "./SubMenu";
import MenuList from "./MenuItems.json";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import FlightTakeoffRoundedIcon from "@mui/icons-material/FlightTakeoffRounded";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { useRecoilState } from "recoil";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { userInfo } from "app/config/States/users";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
const menuIconMap: any = {
  TimelineRoundedIcon: <TimelineRoundedIcon style={{ fontSize: 20 }} />,
  KeyRoundedIcon: <KeyRoundedIcon style={{ fontSize: 20 }} />,
  QueryStatsRoundedIcon: <QueryStatsRoundedIcon style={{ fontSize: 20 }} />,
  NotificationsRoundedIcon: (
    <NotificationsRoundedIcon style={{ fontSize: 20 }} />
  ),
  ReconciliationOutlined: <ReconciliationOutlined style={{ fontSize: 20 }} />,
  FlightTakeoffRoundedIcon: (
    <FlightTakeoffRoundedIcon style={{ fontSize: 16 }} />
  ),
  PercentRoundedIcon: <PercentRoundedIcon style={{ fontSize: 16 }} />,
  MailOutlined: <MailOutlined style={{ fontSize: 16 }} />,
  DescriptionOutlinedIcon: <DescriptionOutlinedIcon style={{ fontSize: 16 }} />,
  SignalCellularAltOutlinedIcon:<SignalCellularAltOutlinedIcon style={{ fontSize: 16 }} />,
};

export default function PrimaryNav(props: any) {
  const [activeMenu, setActiveMenu] = useState("/dashboard");
  const [openNestedMenu, setOpenNestedMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [expanded, setExpanded] = useState(true);

  const handleOpenNestedMenu = (menuKey: string) => {
    if (openSubMenu === menuKey) {
      setOpenSubMenu("");
    } else {
      setActiveMenu(menuKey);
      setOpenSubMenu(menuKey);
    }
    // props.openMenu && props.openMenu();
  };
  const handleCloseNestedMenu = () => {
    setOpenNestedMenu(false);
    props.openMenu && props.openMenu();
  };
  const navigate = useNavigate();
  const handleOpenSubMenu = async (menu: any) => {
    setActiveMenu(menu.route);
    if (menu.length > 0) {
      setOpenNestedMenu(!openNestedMenu);
      props.openMenu && props.openMenu();
    } else {
      navigate(menu.route);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/signin");
  };

  const bottomMenuItems: MenuProps = {
    items: [
      // {
      //   key: "1",
      //   label: (
      //     <>
      //       <div className="UserAvatarInfoPrimaryNav">
      //         <Avatar
      //           style={{ backgroundColor: "#87d068" }}
      //           src="https://api.dicebear.com/7.x/miniavs/svg?seed=1"
      //         />
      //         <div className="UserInfo">
      //           <Typography variant="body2" weight={600}>
      //             John Wick
      //           </Typography>
      //           <Typography variant="xs" weight={400} style={{ color: "gray" }}>
      //             jwick@chapter4.worldwide
      //           </Typography>
      //         </div>
      //       </div>
      //       <Divider style={{ margin: 0, marginTop: 8 }} />
      //     </>
      //   ),
      //   disabled: true,
      // },
      {
        key: "1",
        label: <Link to={"/gamification/profile/"}>Profile</Link>,
      },
      {
        key: "2",
        danger: true,
        label: <div onClick={handleLogout}>Logout</div>,
      },
    ],
  };

  const handleOpenMenuORSub = (menuItem: any) => {
    if (menuItem.subMenus.length === 0) {
      navigate(menuItem.route);
      setActiveMenu(menuItem.route)
    } else {
      handleOpenNestedMenu(menuItem.route);
    }
  };
  return (
    <div className="PrimaryNav" style={{ width: expanded ? 220 : 72 }}>
      <div className="NavigationController">
        <div className="ExpandAction" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ChevronLeftRoundedIcon /> : <ChevronRightRoundedIcon />}
        </div>
        <div className="TopNavigation">
          <div
            className="AppLogo"
            style={{ width: expanded ? "auto" : "100%" }}
          >
            <img alt="appIcon" src={expanded ? AppLogo : AppLogoShort} />
          </div>{" "}
          <div className="MenuContainer">
            {MenuList.map((menuItem: any, key: any) => {
              return (
                <div
                  className={
                    "MenuItem " 
                  }
                >
                  <div
                    className={
                      "PrimaryMenuItem " +
                      (activeMenu === menuItem.route ? " active" : "")
                    }
                    onClick={() => handleOpenMenuORSub(menuItem)}
                    style={{
                      justifyContent: !expanded ? "center" : "space-between",
                    }}
                    // onClick={()=>}
                  >
                    <div className={"MenuLeft" + (expanded ? "" : " spaced")}>
                      {menuIconMap[menuItem.icon]}
                      {expanded ? (
                        <Typography variant="caption">
                          {menuItem.title}
                        </Typography>
                      ) : null}
                    </div>
                    {menuItem.subMenus.length > 0 && expanded ? (
                      <>
                        {openSubMenu === menuItem.route ? (
                          <ExpandLessRoundedIcon />
                        ) : (
                          <ExpandMoreRoundedIcon />
                        )}
                      </>
                    ) : null}
                  </div>
                  {openSubMenu === menuItem.route && expanded ? (
                    <div className="SubMenu">
                      {menuItem.subMenus.map((subMenu: any, subKey: string) => {
                        return (
                          <div
                            className={
                              "MenuItem " +
                              (activeMenu === subMenu.route ? " active" : "")
                            }
                            onClick={() => {
                              setActiveMenu(subMenu.route);
                              navigate(subMenu.route);
                            }}
                          >
                            <div className="MenuLeft">
                              {menuIconMap[subMenu.icon]}
                              <Typography variant="xs">
                                {subMenu.title}
                              </Typography>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}

            {/* <Divider style={{ margin: "6px 0" }} /> */}
            {/* <div className="MenuItem">
              <div className="PrimaryMenuItem">
                <div className="MenuLeft">
                  <NotificationsRoundedIcon
                    onClick={() => {
                      // navigate("/settings/profile");
                      // handleOpenNestedMenu();
                    }}
                    style={{ fontSize: 18 }}
                  />
                  <Typography variant="caption">Notifications</Typography>
                </div>
              </div>
            </div>
            <div className="MenuItem">
              <div className="PrimaryMenuItem">
                <div className="MenuLeft">
                  <SettingsRoundedIcon
                    onClick={() => {
                      // navigate("/settings/profile");
                      // handleOpenNestedMenu();
                    }}
                    style={{ fontSize: 18 }}
                  />
                  <Typography variant="caption">Setting</Typography>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="BottomNavigation">
          <div className="MenuItem" style={{ zIndex: 99999 }}>
            <div className="UserAvatarInfoPrimaryNav">
              <Avatar
                style={{ backgroundColor: "#87d068" }}
                src="https://api.dicebear.com/7.x/miniavs/svg?seed=1"
              />
              {expanded ? (
                <>
                  <div className="UserInfo">
                    <Typography
                      variant="caption"
                      weight={500}
                      style={{ color: "var( --text-black-gray)" }}
                    >
                     {userDetails.name}
                    </Typography>
                    <Typography
                      variant="xs"
                      weight={400}
                      style={{ color: "gray", marginTop: 2 }}
                    >
                      {userDetails.email}
                    </Typography>
                  </div>
                  <Dropdown
                    menu={bottomMenuItems}
                    placement="topRight"
                    arrow
                    overlayStyle={{ zIndex: 99999 }}
                  >
                    <MoreHorizRoundedIcon />
                  </Dropdown>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Drawer
        placement={"left"}
        closable={false}
        onClose={handleCloseNestedMenu}
        open={openNestedMenu}
        width={240}
        mask={false}
        maskClosable
        style={{ marginLeft: 72, borderRight: "1px solid #d7e6f9", padding: 0 }}
        zIndex={1}
      >
        <SubMenu onClose={handleCloseNestedMenu} />
      </Drawer>
    </div>
  );
}
