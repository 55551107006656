import PageHeader from "app/shared/PageHeader";
import "./Reports.scss";
import { AgTableClient } from "app/shared/AgTable";
import SearchInput from "app/shared/SearchInput";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";
import { useEffect, useRef, useState } from "react";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_REPORTS_LIST_BY_WORKSPACE } from "./reports.constants";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import Loader from "app/shared/Loader";
const reportData = [
  {
    name: "R1 Booking Vs Invoice Report",
  },
  {
    name: "R5 Titan Inv Report",
  },
  {
    name: "R2 Honeywell Report",
  },
  {
    name: "R3 ZOHO CORPORATION Report",
  },
  {
    name: "R4 Nestle Report",
  },
];
function ReportPage(props: any) {
  const [reportList, setReportList] = useState([]);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);

  const fetchReportList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_REPORTS_LIST_BY_WORKSPACE);
    setReportList(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchReportList();
  }, [userDetails.currentWorkspace]);
  return (
    <div className="Reports">
      <div className="ScreenContainer">
        <div className="TableContainer">
          {isLoading ? (
            <Loader />
          ) : (
            <AgTableClient
              // @ts-ignore
              rowData={reportList}
              columnDefs={[
                {
                  headerName: "Report Name",
                  field: "name",
                },
                {
                  headerName: "Action",
                  cellRenderer: (params: any) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography
                          variant="caption"
                          style={{
                            color: colorPicker("primary.700"),
                            cursor: "pointer",
                          }}
                          onClick={() => props.openReport(params?.data?.link)}
                        >
                          View Report
                        </Typography>
                      </div>
                    );
                  },
                },
              ]}
              footer={false}
              hideToolbar
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default function Reports(props: any) {
  const tabRef = useRef<any>();
  const [activeKey, setActiveKey] = useState(1);
  const [totalTabs, setTotalTabs] = useState([
    {
      title: "Report",
      id: 1,
      type: "VIEW",
    },
  ]);
  return (
    <div className="Reports">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <CustomReconTabs
              activeKey={activeKey}
              title="Report"
              onTabChange={(tabKey: any) => setActiveKey(tabKey)}
              onTabModify={(tabs: any) => setTotalTabs(tabs)}
              ref={tabRef}
            />
          }
        />
        <div className="TabContainer">
          {totalTabs.map((tab: any, key: any) => {
            return (
              <>
                <div
                  style={{
                    height: "100%",
                    display: activeKey === tab.id ? "flex" : "none",
                  }}
                  key={key}
                >
                  {tab.type === "VIEW" ? (
                    <ReportPage
                      openReport={(pdfData: any) => {
                        tabRef.current.addTab("PDF", pdfData);
                        setActiveKey(activeKey + 1);
                      }}
                    />
                  ) : (
                    <iframe
                      height="100%"
                      style={{ width: "100%", border: "none" }}
                      scrolling="no"
                      title="Iframe"
                      src={tab.viewData}
                    ></iframe>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
