import { Button, Tag } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { colorPicker } from "app/utils/color.helper";
import onboaringIllustration from "static/images/illustration/onboaringIllustration.png";
import Typography from "app/shared/Typography";
import color from "theme/color";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
export default function Greeting(props: any) {
  return (
    <div className="Greeting" style={{ padding: "20px 28px" }}>
      <div style={{ borderBottom: "1px solid #dbdbdb", paddingBottom: 16 }}>
        <Typography style={{ fontSize: 26 }}>
          Revolutionize Your ITC Journey Welcome Aboard!
        </Typography>
      </div>

      <div className="SolutionView">
        <div className="SolutionCard">
          <div className="SolCheck">
            <DoneAllOutlinedIcon style={{ color: "white" }} />
          </div>

          <Typography
            style={{ color: colorPicker("neutral.800") }}
            weight={500}
          >
            GST ITC Analytics
          </Typography>

          <Typography
            variant="xs"
            style={{ marginTop: 12, color: colorPicker("neutral.700") }}
          >
            Track your GST Input Tax Credit and maximise claims.
          </Typography>
        </div>

        <div className="SolutionCard">
          <div className="SolCheck">
            <DoneAllOutlinedIcon style={{ color: "white" }} />
          </div>

          <Typography
            style={{ color: colorPicker("neutral.800") }}
            weight={500}
          >
            Reconcilation
          </Typography>

          <Typography
            variant="xs"
            style={{ marginTop: 12, color: colorPicker("neutral.700") }}
          >
            Automate invoice matching with GST Portal.
          </Typography>
        </div>

        <div className="SolutionCard">
          <div className="SolCheck">
            <DoneAllOutlinedIcon style={{ color: "white" }} />
          </div>

          <Typography
            style={{ color: colorPicker("neutral.800") }}
            weight={500}
          >
            Easy Follow Ups
          </Typography>

          <Typography
            variant="xs"
            style={{ marginTop: 12, color: colorPicker("neutral.700") }}
          >
            Automate followups with your Airlines & Hotels and save time.{" "}
          </Typography>
        </div>

        <div className="SolutionCard">
          <div className="SolCheck">
            <DoneAllOutlinedIcon style={{ color: "white" }} />
          </div>

          <Typography
            style={{ color: colorPicker("neutral.800") }}
            weight={500}
          >
            Complete Visibility
          </Typography>

          <Typography
            variant="xs"
            style={{ marginTop: 12, color: colorPicker("neutral.700") }}
          >
            Get visibility into hotel and flight data of your org.
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: 32, width: 200 }}>
        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={props.goNext}
          style={{ width: "100%" }}
        >
          Let's Go
        </Button>
        <Typography
          style={{
            color: "#736d79",
            marginTop: 4,
            fontSize: 12,
          }}
        >
          Setup less than a minute
        </Typography>
      </div>

      {/* <div
        style={{
          display: "flex",
          // alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <Typography style={{ marginTop: 10, fontSize: 16 }}>
            Ready to put your ITC journey on autopilot?
          </Typography>

          <div style={{ marginTop: 14 }}>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
            >
              <CheckRoundedIcon
                style={{ marginRight: 6, color: colorPicker("green.500") }}
              />
              <Typography>Optimise ITC claimed</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
            >
              <CheckRoundedIcon
                style={{ marginRight: 6, color: colorPicker("green.500") }}
              />
              <Typography>Set payment terms</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
            >
              <CheckRoundedIcon
                style={{ marginRight: 6, color: colorPicker("green.500") }}
              />
              <Typography>Improve vendor filling behavior</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
            >
              <CheckRoundedIcon
                style={{ marginRight: 6, color: colorPicker("green.500") }}
              />
              <Typography>Sync with your ERP</Typography>
            </div>
          </div>

          <div style={{ marginTop: 24 }}>
            <Button
              type="primary"
              icon={<ArrowRightOutlined />}
              onClick={props.goNext}
            >
              Let's Go
            </Button>
            <Typography
              style={{
                color: "#736d79",
                marginTop: 4,
                fontSize: 12,
              }}
            >
              Setup less than a minute
            </Typography>
          </div>
        </div>
      </div> */}
    </div>
  );
}
