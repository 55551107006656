export const API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST=`${process.env.REACT_APP_API_URL}/creds/gstportal/list`;
export const API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST=`${process.env.REACT_APP_API_URL}/creds/airline/list`;
export const API_ENDPOINT_FETCH_EMAIL_CREDENTIALS_LIST=`${process.env.REACT_APP_API_URL}/creds/email/list`;

export const API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE=`${process.env.REACT_APP_API_URL}/creds/gstportal/add`;
export const API_ENDPOINT_GST_CREDENTIAL_UPDATE=`${process.env.REACT_APP_API_URL}/creds/gstportal/update`;

export const API_ENDPOINT_AIRLINE_CREDENTIAL_CREATE=`${process.env.REACT_APP_API_URL}/creds/airline/add`;
export const API_ENDPOINT_AIRLINE_CREDENTIAL_UPDATE=`${process.env.REACT_APP_API_URL}/creds/airline/update`;


export const API_ENDPOINT_EMAIL_GET_WORKSPACE_LIST=`${process.env.REACT_APP_API_URL}/meta/workspace`;
export const API_ENDPOINT_EMAIL_CREATE=`${process.env.REACT_APP_API_URL}/creds/email/add`;

export const API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST=`${process.env.REACT_APP_API_URL}/creds/email/getall`;
export const API_ENDPOINT_EMAIL_CREDENTIAL_MAP=`${process.env.REACT_APP_API_URL}/creds/email/map`;
