import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import { useEffect, useState } from "react";

import Loader from "app/shared/Loader";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";

import "./MMT.scss";

import jsonData from "./data.json";

import { Button, FloatButton, Spin, Tour, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
import Typography from "app/shared/Typography";
export default function OnboardAirlineReconView(props: any) {
  const [tableData, setTableData] = useState(jsonData);
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const openSidebarRenderer = (params: any) => {
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };

  const columnDefs = [
    {
      field: "Invoice",
      headerName: "Invoice",
      enableRowGroup: true,
      invoiceLink: "html.pdf",
      cellRenderer: ToolParamInvoker,
      cellRendererParams: () => {
        return {
          label: "View Invoice",
          handleClick: openSidebarRenderer,
        };
      },
      minWidth: 200,
    },
    {
      field: "Vendor Name",
      headerName: "Vendor Name",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "Claimable Status",
      headerName: "Claimable Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "MATCH",
      formatProps: {
        matchLogic: {
          matchColor: {
            Claimable: "green",
            Unclaimable: "red",
          },
          icon: {
            Claimable: <CheckCircleFilled />,
            Unclaimable: <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },
    {
      field: "Invoice/Note_Number Match Status",
      headerName: "Invoice/Note_Number Match Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "MATCH",
      formatProps: {
        matchLogic: {
          matchColor: {
            Match: "green",
            Mismatch: "red",
          },
          icon: {
            Match: <CheckCircleFilled />,
            Mismatch: <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },

    {
      field: "Supplier_GSTIN Match Status",
      headerName: "Supplier_GSTIN Match Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "MATCH",
      formatProps: {
        matchLogic: {
          matchColor: {
            Match: "green",
            Mismatch: "red",
          },
          icon: {
            Match: <CheckCircleFilled />,
            Mismatch: <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },
    {
      headerName: "2A/2B",
      headerClass: "custom-header",
      children: [
        {
          field: "2A/2B-Invoice/Note_Number",
          headerName: "2A/2B-Invoice/Note_Number",
          enableRowGroup: true,
          minWidth: 200,
        },
        {
          field: "2A/2B-Supplier_GSTIN",
          headerName: "2A/2B-Supplier_GSTIN",
          enableRowGroup: true,
          minWidth: 200,
        },
        {
          field: "2A/2B-Customer_GSTIN",
          headerName: "2A/2B-Customer_GSTIN",
          enableRowGroup: true,
          minWidth: 200,
        },
        {
          field: "2A/2B-CGST",
          headerName: "2A/2B-CGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "2A/2B-SGST",
          headerName: "2A/2B-SGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "2A/2B-IGST",
          headerName: "2A/2B-IGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },

        {
          field: "Note Type",
          headerName: "Note Type",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
        },
        {
          field: "Type",
          headerName: "Type",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
        },
        {
          field: "Financial_Year",
          headerName: "Financial_Year",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
        },
        {
          field: "2A/2B-Invoice/Note_Date",
          headerName: "2A/2B-Invoice/Note_Date",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "DATE",
          ...handleCellRenderer(),
        },

        {
          field: "2A/2B-Tax_Rate",
          headerName: "2A/2B-Tax_Rate",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
        },
        {
          field: "2A/2B-Taxable",
          headerName: "2A/2B-Taxable",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "2A/2B-Total_GST",
          headerName: "2A/2B-Total_GST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "2A/2B-Total_Amount",
          headerName: "2A/2B-Total_Amount",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
        },
      ],
    },

    {
      headerName: "Invoice",
      children: [
        {
          field: "Invoice-Invoice/Note_Number",
          headerName: "Invoice-Invoice/Note_Number",
          enableRowGroup: true,
          minWidth: 200,
        },
        {
          field: "Invoice-Supplier_GSTIN",
          headerName: "Invoice-Supplier_GSTIN",
          enableRowGroup: true,
          minWidth: 200,
        },

        {
          field: "Invoice-Invoice/Note_Date",
          headerName: "Invoice-Invoice/Note_Date",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
        },

        {
          field: "Invoice-Customer_GSTIN",
          headerName: "Invoice-Customer_GSTIN",
          enableRowGroup: true,
          minWidth: 200,
        },
        {
          field: "Invoice-Tax_Rate",
          headerName: "Invoice-Tax_Rate",
          enableRowGroup: true,
          minWidth: 200,
          columnGroupShow: "open",
        },
        {
          field: "Invoice-Taxable",
          headerName: "Invoice-Taxable",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "Invoice-CGST",
          headerName: "Invoice-CGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "Invoice-SGST",
          headerName: "Invoice-SGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "Invoice-IGST",
          headerName: "Invoice-IGST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "Invoice-Total_GST",
          headerName: "Invoice-Total_GST",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "Invoice-Total_Amount",
          headerName: "Invoice-Total_Amount",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "AMOUNT",
          ...handleCellRenderer(),
        },
      ],
    },

    {
      field: "GST Difference",
      headerName: "GST Difference",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "AMOUNT",
    },

    {
      headerName: "Booking",
      children: [
        {
          field: "Ticket/PNR",
          headerName: "Ticket/PNR",
          enableRowGroup: true,
          minWidth: 200,
        },
      ],
    },
  ];

  useEffect(() => {
    // fetchAirlineredentials();
  }, []);

  // const steps: any = [
  //   {
  //     selector: ".g-root-wrapper",
  //     content: "This is my first Step",
  //   },
  // ];

  return (
    <div className="TableContainer" style={{ height: "100%" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <AgTableClient
          // @ts-ignore
          rowData={tableData}
          columnDefs={columnDefs}
          groupPanel
          enablePDFViewer
          pdfDataKey="2A/2B-Invoice/Note_Number"
          onColGroup={props.onColGroup && props.onColGroup}
        />
      )}

      <FloatButton
        onClick={props.goNext}
        style={{ marginRight: 24, width: 200 }}
        shape="square"
        type="primary"
        icon={
          <div style={{ display: "flex", width: "100%" }}>
            <Typography style={{ color: "white", marginRight: 6 }}>
              Next
            </Typography>
            <RightCircleOutlined />
          </div>
        }
      />

      {contextHolder}
    </div>
  );
}
