import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "../AgTable.scss";
import { Button, Empty, Tag } from "antd";
import {
  DownloadOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { handleCellRenderer } from "../AgUtility";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import PDFViewerTool from "../HelperTools/PDFViewerTool";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
const AgTableServer = forwardRef((props, ref) => {
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [userDetails, _] = useRecoilState(userInfo);
  const [isLoading, setLoading] = useState(false);

  const gridRef = useRef();

  const processColumnDefs = (columnDefs) => {
    return columnDefs.map((obj) => ({
      ...obj, // Spread the original object
      ...(obj.formatType ? handleCellRenderer() : {}), // Add the new key-value pair
    }));
  };

  const fetchServerSide = useCallback(async () => {
    // Update the grid data

    gridApi.setServerSideDatasource({
      getRows: function (params) {
        setLoading(true);
        // Use the fetched data to update the grid
        fetch(props.endpoint || "", {
          method: "POST",
          body: JSON.stringify({ ...params.request, ...props.serverPayload }),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
            workspaceId: localStorage.getItem("currentWorkspace"),
          },
        })
          .then((httpResponse) => httpResponse.json())
          .then((response) => {
            params.success({
              rowData: response.data.rows,
            });
            setRowData(response.data.rows);
            // handleColumnRowGroupChanged(params);
          })
          .catch((error) => {
            console.error(error);
            params.fail();
          });
        setLoading(false);
      },
    });
  }, [gridApi]);

  useEffect(() => {
    if (gridApi) {
      fetchServerSide();
    }
  }, [fetchServerSide, gridApi, userDetails.currentWorkspace]);

  const onGridReady = useCallback(async (params) => {
    setGridApi(params.api);
    // params.api.setServerSideDatasource(datasource);
    setColumnDefs(processColumnDefs(props.columnDefs));
  }, []);

  const exportDataToExcel = useCallback(() => {
    gridRef.current.api.exportDataAsExcel(); // Access grid API from the ref
  }, [gridRef]);

  React.useImperativeHandle(ref, () => ({
    exportDataToExcel,
  }));

  return (
    <div className="AgTable">
      {/* <div className="TableActionContainer">
        <div className="LeftContainer">{props.leftTopActions}</div>
        <div className="RightContainer">
          {props.rightTopActions}
          <div className="ActionButton">
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              shape="round"
              size="small"
              style={{ fontSize: 12 }}
            >
              Export
            </Button>
          </div>
        </div>
      </div> */}
      <div className="ag-theme-alpine">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        ) : (
          <AgGridReact
            rowModelType="serverSide"
            columnDefs={columnDefs || []}
            pagination={props.pagination}
            paginationPageSize={500}
            rowSelection="multiple"
            suppressCellSelection={true}
            resizable={true}
            enablePivot={true}
            enableSorting={true}
            enableFilter={true}
            floatingFilter
            groupMultiAutoColumn={true}
            rowGroupPanelShow="always"
            pivotPanelShow="always"
            onGridReady={onGridReady}
            ref={gridRef}
            sideBar={{
              toolPanels: [
                {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                },
                {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel",
                },
                // {
                //   id: "pdfRenderer",
                //   labelDefault: "PDF View",
                //   labelKey: "pdfRenderer",
                //   iconKey: "pdf-file",
                //   width: 500,
                //   toolPanel: PDFViewerTool,
                // },
              ],
            }}
          />
        )}
      </div>
    </div>
  );
});

export default AgTableServer;
