import { Avatar, Badge, Breadcrumb, Dropdown, Layout, MenuProps } from "antd";

import "./PageHeader.scss";
import SearchInput from "../SearchInput";

import MenuList from "../PrimaryNav/MenuItems.json";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Typography from "../Typography";
import { colorPicker } from "app/utils/color.helper";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
export default function PageHeader(props: any) {
  const [breadcrumItem, setBreadcrumbItem] = useState<any[]>([]);
  const location = useLocation();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  console.log("userinfo", userDetails);
  useEffect(() => {
    let menuItem = MenuList.find(
      (item: any) => item.route === location.pathname
    );
    if (menuItem) {
      setBreadcrumbItem([menuItem]);
    }
  }, [location.pathname]);

  const handleSelectWorkspce = (workspaceInfo: any) => {
    localStorage.setItem("currentWorkspace", workspaceInfo?.id);
    setUserDetails({ ...userDetails, currentWorkspace: workspaceInfo });
  };

  const getDropdownItems = () => {
    let workspaces = userDetails?.workspaces;
    let items: any = [];
    workspaces.forEach((element: any) => {
      items.push({
        label: (
          <div
            style={{ display: "flex", alignItems: "center", width: 170 }}
            onClick={() => handleSelectWorkspce(element)}
          >
            <div style={{ width: 28 }}>
              <Avatar
                size={22}
                style={{
                  backgroundColor: PickWorkspaceColor(element.name),
                  color: "#f56a00",
                  // cursor: "pointer",
                  marginRight: 6,
                }}
              >
                <Typography variant="xs" style={{ color: "white" }}>
                  {`${userDetails?.currentWorkspace?.name?.[0]}${userDetails?.currentWorkspace?.name?.[1]}`}
                </Typography>
              </Avatar>
            </div>

            <p
              style={{
                overflow: "hidden !important",
                whiteSpace: "nowrap ",
                textOverflow: "ellipsis",
                color: "var(--text-color-black)",
                fontSize: 12,
              }}
            >
              {element.name}
            </p>
          </div>
        ),
        key: element.id,
      });
    });
    console.log("final itesm", items);
    return items;
  };

  const workspaceColorMap = [
    "#4555eb",
    "#209f85",
    "#fa694a",
    "#2c3e50",
    "#e74c3c",
  ];

  const PickWorkspaceColor = (word: string) => {
    const firstChar = word.charAt(0).toLowerCase();
    const alphabetIndex = firstChar.charCodeAt(0) - "a".charCodeAt(0);
    const colorIndex = Math.floor(
      alphabetIndex / (26 / workspaceColorMap.length)
    );
    return workspaceColorMap[colorIndex];
  };

  return (
    <div className="PageHeader">
      <div className="HeaderLeft">
        <Typography
          weight={500}
          style={{ color: "var(--text-color-black)", fontSize: 14 }}
        >
          {props.title}
        </Typography>

        <div
          className="ItemContainer externalAction"
          style={{ marginRight: 24 }}
        >
          {props.leftActions}
        </div>
      </div>
      <div className="HeaderRight">
        <div
          className="ItemContainer externalAction"
          style={{ marginRight: 24 }}
        >
          {props.rightActions}
        </div>
        <div className="ItemContainer">
          <Dropdown
            menu={{ items: getDropdownItems() }}
            trigger={["click"]}
            overlayStyle={{
              width: 200,
              height: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Avatar
                size={24}
                style={{
                  backgroundColor: workspaceColorMap[1],
                  color: workspaceColorMap[1],
                  cursor: "pointer",
                  borderRadius: 8,
                  marginRight: 6,
                }}
              >
                <Typography style={{ color: "white" }} variant="caption">
                  {`${userDetails?.currentWorkspace?.name?.[0]}`}
                </Typography>
              </Avatar>

              <Typography variant="caption">
                {userDetails?.currentWorkspace?.name}
              </Typography>

              <ExpandMoreRoundedIcon />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
