// EditButtonRenderer.jsx
import React, { useState } from "react";
import {
  EditFilled,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";

const AgTableEditButton = (props) => {
  const [editMode, setEditMode] = useState(false);

  const handleCloseEditing = () => {
    setEditMode(false);
    props.colDef.editFunction(props, false);
  };

  return editMode ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CheckCircleFilled
        style={{
          fontSize: 20,
          cursor: "pointer",
          marginRight: 12,
          color: "#43a522",
        }}
        onClick={handleCloseEditing}
      />
      <CloseCircleFilled
        style={{ fontSize: 20, cursor: "pointer", color: "#dd5061" }}
        onClick={handleCloseEditing}
      />
    </div>
  ) : (
    <EditFilled
      style={{
        fontSize: 18,
        cursor: "pointer",
        opacity: props?.data?.status === "ACTIVE" ? 0.5 : 1,
        pointerEvents: props?.data?.status === "ACTIVE" ? "none" : "auto",
      }}
      onClick={() => {
        setEditMode(true);
        props.colDef.editFunction(props, true, props?.colDef?.focusKey);
      }}
    />
  );
};

export default AgTableEditButton;
