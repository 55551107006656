import { Auth } from ".";
import Login from "./components/Login";
import LoginOTP from "./components/LoginOTP";
import OAuthCallback from "./components/OAuthCallback";

//eslint-disable-next-line
export default {
  path: "/auth/",
  element: <Auth />,
  strict: true,
  children: [
    {
      path: "/auth/signin",
      element: <Login />,
      exact: true,
    },
    {
      path: "/auth/signin_otp",
      element: <LoginOTP />,
      exact: true,
    },
    {
      path: "/auth/oauth/callback",
      element: <OAuthCallback />,
      exact: true,
    },
  ],
};
