import { Button, Divider, Input, Spin, Tag, Upload, message } from "antd";
import {
  StarFilled,
  LoadingOutlined,
  DownloadOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { parse } from "papaparse"; // Library for parsing CSV
import { useState } from "react";
import * as csv from "csvtojson";
import { json, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import Uploader from "app/shared/Uploader";
import TextField from "app/shared/TextField";
import { colorPicker } from "app/utils/color.helper";
import Typography from "app/shared/Typography";
import { apiPost } from "app/services/apiServices";
import PanInputHandler from "../PanInputHandler";
import { API_ENDPOINT_ONBOARDING_FETCH_GSTIN_INFO, API_ENDPOINT_ONBOARDING_FETCH_PAN_GSTIN } from "../../onboarding.constants";

export default function AddBusiness(props) {
  const [userDetails, setUserDetails] = useRecoilState(userInfo);

  const location = useLocation();

  const [csvData, setCSVData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const [jsonData,setJSONData]=useState([])

  const [panno, setPAN] = useState([]);

  const uploaderProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      handleUpload(info);
    },
  };

  

  const processFetchGSTIN = async (jsonData) => {
    setLoading(true);
    let gstinCreds = [];
    for (let i = 0; i < jsonData.length; i++) {
      // Example: Call API 5 times
      try {
        const response = await apiPost(
          API_ENDPOINT_ONBOARDING_FETCH_GSTIN_INFO,
          { gstin: jsonData[i].gstin }
        );
        console.log(response); // Handle the response data
        if (response) {
          gstinCreds.push({
            ...jsonData[i],
            ...response.data,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setLoading(false);
    props.goNext && props.goNext({ type: "GSTIN", data: gstinCreds });
  };

  const handleUpload = async (file) => {
    if (!file.originFileObj) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const text = e.target.result;
        const jsonArray = await csv().fromString(text);
        console.log("jsonArray", jsonArray);
        // setRowData(jsonArray);
        // props.onNext(jsonArray);
        // uploadcredfilestoS3(jsonArray);
        processFetchGSTIN(jsonArray);
        setJSONData(jsonArray)
      };

      reader.readAsText(file);
    }
  };

  const downloadSample = async () => {
    let csvString = `gstin,username,password
                    09AABCG3241G1ZL,testuser1,user_password
                    09AABCG3141G1ZL,testuser2,user_password
                    09AABCG8111G1ZL,testuser3,user_password`;
    try {
      // Convert the CSV string to a Blob
      const csvBlob = new Blob([csvString], { type: "text/csv" });

      // Convert the Blob object to a data URL
      const csvDataUrl = URL.createObjectURL(csvBlob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = csvDataUrl;
      link.setAttribute("download", "sample_credentials.csv");

      // Trigger the download by programmatically clicking the anchor element
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV file:", error);
    }
  };

  const CSVtoJSON = (file) => {
    return new Promise((resolve, reject) => {
      parse(file, {
        header: true,
        complete: (results) => {
          resolve(results.data);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  const getSingleGstinInfo=async(gstinNumber)=>{
    try {
      const response = await apiPost(
        API_ENDPOINT_ONBOARDING_FETCH_GSTIN_INFO,
        { gstin: gstinNumber }
      );
      if (response.status) {
        return response.data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const handleSingleUpload = async () => {
    let gstinCreds = [];
    setLoading(true);
    for (let i = 0; i < panno.length; i++) {
      // Example: Call API 5 times
      try {
        const response = await apiPost(
          API_ENDPOINT_ONBOARDING_FETCH_PAN_GSTIN,
          { pan: panno[i] }
        );
        console.log(response); // Handle the response data
        if (response.status) {
          for (let idx in response.data.gstinResList) {
            let gstinData= await getSingleGstinInfo(response.data.gstinResList[idx].gstin)
            gstinCreds.push({
              ...response.data.gstinResList[idx],
              ...gstinData,
              username: "",
              password: "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setLoading(false);
    console.log("gst creds", gstinCreds);
    props.goNext &&
      props.goNext({
        type: "PAN",
        data: gstinCreds,
      });
  };

  return (
    <div
      className="Greeting"
      style={{
        padding: "20px 28px",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        ...(isLoading ? { justifyContent: "center" } : {}),
      }}
    >
      {isLoading ? (
        <div className="FinishScreen">
          <Typography
            style={{ marginTop: 12, color: colorPicker("neutral.900") }}
            weight={600}
            variant="h5"
          >
            Verifying Credentials
          </Typography>
          <Typography
            style={{
              marginTop: 6,
              textAlign: "center",
              color: colorPicker("neutral.800"),
            }}
            variant="caption"
          >
            We have received your credentials and started Verifying.{" "}
            <b>Might take upto couple of minute</b>
            <Spin
              style={{ marginLeft: 6 }}
              indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
            />
          </Typography>

          <Typography
            style={{
              marginTop: 6,
              textAlign: "center",
              color: colorPicker("neutral.800"),
            }}
            variant="xs"
            weight={600}
          >
            Please wait for a while or you can <span style={{textDecoration:'underline',color:colorPicker("primary.700"),cursor:'pointer'}} onClick={()=>props.goNext({ type: "GSTIN", data: jsonData })}>SKIP</span>
          </Typography>
        </div>
      ) : (
        <>
          <div style={{ borderBottom: "1px solid #dbdbdb", paddingBottom: 16 }}>
            <Typography
              style={{ fontSize: 26, color: colorPicker("neutral.950") }}
            >
              It's time to add your business and watch it thrive!
            </Typography>
          </div>
          <div className="BusinessForm">
            <div className="SingleCredentials">
              <Typography
                style={{ marginBottom: 12, color: colorPicker("neutral.800") }}
                variant="h6"
              >
                <IdcardOutlined /> Fetch Via PAN
              </Typography>
              <div style={{ marginTop: 14, width: "100%" }}>
                <PanInputHandler onChange={(panno) => setPAN(panno)} />
              </div>

              <div
                style={{
                  marginTop: 24,
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button
                  type="primary"
                  onClick={handleSingleUpload}
                  loading={isLoading}
                  disabled={panno.length === 0}
                >
                  Submit
                </Button>
              </div>
            </div>
            <div className="CustomDivider"></div>

            <div className="UploadSection">
              <Typography
                style={{ marginBottom: 24, color: colorPicker("neutral.800") }}
                variant="h6"
              >
                Upload Bulk Credential
              </Typography>

              <div style={{ width: "100%" }}>
                <Uploader colored customHeight={260} onSelect={handleUpload} />
              </div>
              <Tag
                icon={<DownloadOutlined />}
                onClick={downloadSample}
                style={{ cursor: "pointer", marginTop: 24 }}
              >
                Download Sample Data
              </Tag>
            </div>
          </div>
        </>
      )}

      {contextHolder}
    </div>
  );
}
