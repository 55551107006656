import { Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { FormattedNumber } from "react-intl";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { get as getDataWithLodash } from "lodash";
import "./AgTable.scss";

function formatFileSize(bytes: any) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

const handleDataFormat = (
  formatType: string,
  formatData: any,
  formatProps: any,
  fullParamData: any
) => {
  switch (formatType) {
    case "CHIP":
      return <Tag color={formatProps?.chipColor}>{formatData}</Tag>;
    case "CHIP_SHORT_TEXT":
      return (
        <Tag color={formatProps?.chipColor}>
          {formatData.toString().slice(0, 8)}
        </Tag>
      );
    case "AMOUNT":
      return (
        <div style={{ textAlign: "right" }}>
          <FormattedNumber value={formatData} style="currency" currency="INR" />
        </div>
      );
    case "FILE_SIZE":
      return formatData ? formatFileSize(formatData) : "0 B";

    case "DATE":
      return formatData
        ? moment(formatData).format(formatProps?.dateFormat || "DD-MM-YYYY")
        : "NA";
    case "TAGS":
      let tags: any = [];
      (formatData || []).forEach((element: any) => {
        tags.push(
          <Tag
            // onClick={() => applyFilter(fieldKey, element)}
            color={formatProps?.chipColor}
            id="filterTag"
          >
            {element}
          </Tag>
        );
      });
      return tags;
    case "MATCH":
      return (
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Tag
            color={formatProps?.matchLogic?.matchColor[formatData] || "red"}
            style={{
              alignItems: "center",
              display: "flex",
              width: "fit-content",
            }}
          >
            {formatProps?.matchLogic?.icon?.[formatData] || (
              <CloseCircleFilled />
            )}
            <span />
            {formatData || formatProps?.defaultText || "NA"}
          </Tag>
        </div>
      );
    case "MATCH_COMPARE":
      let isMatched = true;
      (formatProps?.compareObjects || []).forEach((objectPath: string) => {
        if (formatData !== getDataWithLodash(fullParamData, objectPath, "NA")) {
          isMatched = false;
          return;
        }
      });

      return (
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          {isMatched ? (
            <CheckCircleFilled style={{ color: "#43a522", marginRight: 6 }} />
          ) : (
            <CloseCircleFilled style={{ color: "#dd5061", marginRight: 6 }} />
          )}

          <span />
          {formatData || formatProps?.defaultText || "NA"}
        </div>
      );
  }
};

export const handleCellRenderer = () => {
  return {
    valueGetter: (params: any) => {
      if (params.node.group) {
        // If the node is a group, return the group key
        return params.node.key;
      } else {
        // If the node is a leaf node, return the individual value
        return getDataWithLodash(params?.data, params?.colDef?.field, "NA");
      }
    },
    cellRenderer: (params: any) =>
      params.colDef.cellRenderer === "agGroupCellRenderer"
        ? params.valueFormatted || params.value
        : getDataWithLodash(params?.data, params?.colDef?.field, "NA") === "NA"
        ? "-"
        : handleDataFormat(
            params?.colDef?.formatType,
            getDataWithLodash(params?.data, params?.colDef?.field, "NA"),
            params?.colDef?.formatProps,
            params?.data
          ),
  };
};
