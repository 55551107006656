import { apiGet } from "app/services/apiServices";
import { AgTableServer } from "app/shared/AgTable";
import { useEffect, useRef, useState } from "react";
import { API_ENDPOINT_RECONCILATION_FETCH_GST_DATA } from "../../reconcilation.constants";
import Loader from "app/shared/Loader";
import { ExportOutlined } from "@ant-design/icons";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

import { Button, Empty, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";
import PageHeader from "app/shared/PageHeader";
import CustomReconTabs from "../CustomReconTabs";
import { useRecoilState } from "recoil";
import { tabViewStates } from "app/config/States/users";
function GSTReconComponent(props: any) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const gridRef = useRef<any>(null);
  const columnDefs = [
    {
      field: "count",
      headerName: "Count",
      enableRowGroup: true,
      enableValue: true,
      noConfigCheck: true,
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "right" },
      hide: true,
    },

    {
      field: "result",
      headerName: "Match Status",
      enableRowGroup: true,
      formatType: "MATCH",
      formatProps: {
        matchLogic: {
          icon: {
            MISSING: (
              <FilterListOffIcon style={{ fontSize: 16, marginRight: 2 }} />
            ),
            PROBABLE: (
              <StackedLineChartIcon style={{ fontSize: 16, marginRight: 2 }} />
            ),
            MATCH: (
              <MenuOutlinedIcon style={{ fontSize: 16, marginRight: 2 }} />
            ),
          },
          matchColor: {
            MISSING: "red",
            PROBABLE: "yellow",
            MATCH: "green",
          },
        },
      },
    },

    {
      field: "matchresult[0].trueValues",
      headerName: "Match Summary",
      enableRowGroup: true,
      minWidth: 300,
      formatType: "TAGS",
      formatProps: {
        chipColor: "green",
      },
    },
    {
      field: "matchresult[0].falseValues",
      headerName: "Mismatch Summary",
      enableRowGroup: true,
      minWidth: 300,
      formatType: "TAGS",
      formatProps: {
        chipColor: "red",
      },
    },

    {
      field: "vendorType",
      headerName: "Vendor Type",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "gstexemption",
      headerName: "GST Exemption",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "einvoiceDate",
      headerName: "E-Invoice Date",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "enablementStatus",
      headerName: "Enablement Status",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      headerName: "IRN",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "IRN Number - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.irn",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.Irn"],
          },
        },

        {
          headerName: "IRN Number -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.Irn",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.irn"],
          },
        },
      ],
      entityKey: "colmatch0",
    },

    {
      headerName: "Invoice Number",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Invoice Number - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.inum",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedQRCodeParsed.DocNo"],
          },
        },

        {
          headerName: "Invoice Number -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedQRCodeParsed.DocNo",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.inum"],
          },
        },
      ],
      entityKey: "colmatch0",
    },
    {
      headerName: "Seller GSTIN",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Seller GSTIN - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.ctin",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedInvoiceParsed.SellerDtls.Gstin"],
          },
        },

        {
          headerName: "Seller GSTIN -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedInvoiceParsed.SellerDtls.Gstin",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.ctin"],
          },
        },
      ],
      entityKey: "colmatch0",
    },
    {
      headerName: "Buyer GSTIN",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Buyer GSTIN - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.gstin",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedInvoiceParsed.BuyerDtls.Gstin"],
          },
        },

        {
          headerName: "Buyer GSTIN -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedInvoiceParsed.BuyerDtls.Gstin",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.gstin"],
          },
        },
      ],
      entityKey: "colmatch0",
    },
    {
      headerName: "Seller PAN",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Seller PAN - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.ctin",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedInvoiceParsed.SellerDtls.Gstin"],
          },
        },

        {
          headerName: "Seller PAN -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedInvoiceParsed.SellerDtls.Gstin",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.ctin"],
          },
        },
      ],
      entityKey: "colmatch0",
    },

    {
      headerName: "Buyer PAN",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Buyer PAN - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.gstin",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedInvoiceParsed.BuyerDtls.Gstin"],
          },
        },

        {
          headerName: "Buyer PAN -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedInvoiceParsed.BuyerDtls.Gstin",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.gstin"],
          },
        },
      ],
      entityKey: "colmatch0",
    },

    {
      headerName: "IRN Generation Date",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "IRN Generation Date - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.irngendate",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedQRCodeParsed.IrnDt"],
          },
        },

        {
          headerName: "IRN Generation Date -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedQRCodeParsed.IrnDt",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.irngendate"],
          },
        },
      ],
      entityKey: "colmatch0",
    },

    {
      headerName: "Invoice Date",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Invoice Date - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.dt",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedQRCodeParsed.DocDt"],
          },
        },

        {
          headerName: "Invoice Date -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedQRCodeParsed.DocDt",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.dt"],
          },
        },
      ],
      entityKey: "colmatch0",
    },

    {
      headerName: "Place of Supply",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Place of Supply - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.pos",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedInvoiceParsed.BuyerDtls.Pos"],
          },
        },

        {
          headerName: "Place of Supply -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedInvoiceParsed.BuyerDtls.Pos",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.pos"],
          },
        },
      ],
      entityKey: "colmatch0",
    },

    {
      headerName: "Document Type",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Document Type - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.typ",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedInvoiceParsed.DocDtls.Typ"],
          },
        },

        {
          headerName: "Document Type -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedInvoiceParsed.DocDtls.Typ",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.ty"],
          },
        },
      ],
      entityKey: "colmatch0",
    },

    {
      headerName: "Filling Period",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Filling Period - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.supprd",
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedQRCodeParsed.DocDt"],
          },
        },

        {
          headerName: "Filling Period -  IRN",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          field: "irnmatch.SignedQRCodeParsed.DocDt",
          enableRowGroup: true,
          minWidth: 200,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.supprd"],
          },
        },
      ],
      entityKey: "colmatch0",
    },

    {
      headerName: "Total Amount",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      isGroup: true,
      children: [
        {
          headerName: "Total Amount - 2B",
          columnGroupShow: ["closed", "open"],
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          field: "twobmatch.val",
          minWidth: 200,

          formatType: "MATCH_COMPARE",
          enableValue: true,

          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["irnmatch.SignedInvoiceParsed.ValDtls.TotInvVal"],
          },
        },

        {
          headerName: "Total Amount -  IRN",
          columnGroupShow: "open",
          filter: "agNumberColumnFilter",
          field: "irnmatch.SignedInvoiceParsed.ValDtls.TotInvVal",
          enableRowGroup: true,
          minWidth: 200,
          enableValue: true,
          formatType: "MATCH_COMPARE",
          ...handleCellRenderer(),
          formatProps: {
            compareObjects: ["twobmatch.val"],
          },
        },
      ],
      entityKey: "colmatch0",
    },

    {
      headerName: "Total Amount Difference",
      field_table: "return_2b",
      noConfigCheck: true,
      entityKey: "val",
      columnGroupShow: "open",
      renderType: "CHIP",
      filter: "agNumberColumnFilter",
      field: "matchdiff.val",
      enableValue: true,
      alias: ["matchdiff.val"],
      minWidth: 240,
      extractionType: "TEXT",
      extractionPlace: "SELF",
    },

    {
      headerName: "Taxable Amount",
      showRowGroup: true,
      cellRenderer: "agGroupCellRenderer",
      children: [
        {
          headerName: "Taxable Amount - IRN",
          field_table: "return_irn",
          renderFunc: "MATCH_CHECK",
          entityKey: "irnmatch.SignedInvoiceParsed.ValDtls.AssVal",
          columnGroupShow: ["closed", "open"],
          filter: "agNumberColumnFilter",
          field: "irnmatch.SignedInvoiceParsed.ValDtls.AssVal",
          renderType: "TEXT",
          alias: ["items[0].txval"],
          minWidth: 200,
          enableValue: true,
          showDiff: "matchdiff[0].txval",
          extractionType: "AMOUNT",
          extractionPlace: "SELF",
          dataKey: "SignedInvoiceParsed.ValDtls.AssVal",
        },
        {
          headerName: "Taxable Amount - 2B",
          field_table: "return_2b",
          renderFunc: "STATIC",
          entityKey: "twobmatch.items[0].txval",
          columnGroupShow: "open",
          renderType: "TEXT",
          filter: "agNumberColumnFilter",
          field: "twobmatch.items[0].txval",
          enableValue: true,
          alias: ["SignedInvoiceParsed.ValDtls.AssVal"],
          minWidth: 200,
          showDiff: "matchdiff[0].txval",
          extractionType: "AMOUNT",
          extractionPlace: "SELF",
          dataKey: "items[0].txval",
        },
      ],
      entityKey: "colmatch5",
    },
  ];

  const handleExportTableData = () => {
    console.log("calling export funciton", gridRef);

    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  return (
    <div className="TableContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <AgTableServer
          //@ts-ignore
          columnDefs={columnDefs}
          endpoint={API_ENDPOINT_RECONCILATION_FETCH_GST_DATA}
          // endpoint={"http://192.168.1.59:7001/api/recon/hotel"}
          enablePDFViewer
          ref={gridRef}
        />
      )}
      {contextHolder}
    </div>
  );
}

export default function GSTReconcilation(props: any) {
  const [activeKey, setActiveKey] = useState(1);

  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);

  return (
    <div className="ScreenContainer">
      <PageHeader
        leftActions={
          <CustomReconTabs
            activeKey={activeKey}
            title="GST Recon"
            onTabChange={(tabKey: number) => setActiveKey(tabKey)}
            onTabModify={(tabs: any) =>
              setTabView({ ...tabViews, gst_recon: tabs })
            }
            defaultTabs={tabViews.gst_recon}
          />
        }
        rightActions={
          <div>
            <Button
              type="primary"
              size="small"
              icon={<ExportOutlined />}
              // onClick={handleExportTableData}
              // style={{ marginRight: 12 }}
            >
              Export
            </Button>
          </div>
        }
      />
      <div className="TabContainer">
        {(tabViews.gst_recon || []).map((tab: any, key: any) => {
          return (
            <div
              style={{
                height: "100%",
                display: activeKey === tab.id ? "flex" : "none",
              }}
              key={key}
            >
              <GSTReconComponent />
            </div>
          );
        })}
      </div>
    </div>
  );
}
