import authRoute from "app/scenes/Auth/auth.routes";
import PageNotFound from "app/scenes/Common/PageNotFound/PageNotFound";
import Onboarding from "app/scenes/Onboarding";
import onboadingRoutes from "app/scenes/Onboarding/onboading.routes";
import masterRoutes from "app/shared/MasterLayout/master.routes";

const childRoutes = [authRoute, masterRoutes, onboadingRoutes];

const routes = [
  ...childRoutes,
  {
    path: "*",
    element: <PageNotFound />,
    strict: true,
  },
];

export default routes;
