import {
  Button,
  FloatButton,
  Spin,
  Tag,
  Typography,
  Upload,
  UploadProps,
  message,
} from "antd";
import {
  LoadingOutlined,
  RightCircleOutlined,
  ExportOutlined,
  DownloadOutlined,
  CheckOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { colorPicker } from "app/utils/color.helper";
import onboaringIllustration from "static/images/illustration/onboaringIllustration.png";
import { AgTableClient } from "app/shared/AgTable";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import csv from "csvtojson";
import { apiPost } from "app/services/apiServices";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import moment from "moment";

export default function ValidateCredentials(props: any) {
  const gridRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [tableData, setTableData] = useState([]);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);


  useEffect(() => {
    if (props.tableData.data) {
      setTableData(props.tableData.data);
      if (props.tableData.type !== "PAN")
        scrapperVerifyCredentials(props.tableData.data);
    }
  }, [props.tableData]);

  const defaultDefs = [
    {
      headerName: "GSTIN",
      field: "gstin",
    },
    {
      headerName: "Legal Name",
      field: "lgnm",
    },
    {
      headerName: "Trade Name",
      field: "tradeNam",
    },
    {
      headerName: "E-Invoice",
      field: "einvoiceStatus",
    },
    {
      headerName: "Username",
      field: "username",
      editable: true,
    },
    {
      headerName: "Password",
      field: "password",
      editable: true,
    },
  ];
  let statusColumn = {
    headerName: "Status",
    field: "status",
    cellRenderer: (params: any) => {
      return params?.data?.status === "VALID" ? (
        <Tag color={"green"} icon={<CheckCircleFilled />}>
          Valid
        </Tag>
      ) : params?.data?.status === "INVALID" ? (
        <Tag color={"red"} icon={<CloseCircleFilled />}>
          Invalid
        </Tag>
      ) : (
        <Tag color="orange">
          <Spin
            style={{ marginRight: 6 }}
            indicator={
              <LoadingOutlined
                style={{ fontSize: 14, color: "#d46b08" }}
                spin
              />
            }
          />
          Processing
        </Tag>
      );
    },
  };

  let editColumn = {
    field: "action",
    headerName: "Action",
    enableRowGroup: true,
    editRow: true,
    focusKey: "username",
    cellRenderer: AgTableEditButton,
    cellRendererParams: {
      onClick: (params: any) => console.log(params), // Define your edit logic here
    },
  };
  const location = useLocation();
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isSubmitted, setSubmitted] = useState(false);
  const [hasCSVError, setHasCSVError] = useState(false);
  const [isLoading,setLoading]=useState(false)

  useEffect(() => {
    if (props.tableData.type === "GSTIN") {
      setColumnDefs([...defaultDefs, statusColumn]);
    } else {
      setColumnDefs([...defaultDefs, editColumn]);
    }
  }, [props.tableData]);

  const scrapperVerifyCredentials = async (tableDataList: any[]) => {
    for (let i = 0; i < tableDataList.length; i++) {
      // Example: Call API 5 times
      try {
        const response = await apiPost(
          `https://1606-13-232-185-57.ngrok-free.app/login`,
          tableDataList[i]
        );
        console.log(response); // Handle the response data
        setTableData((prevData) => {
          const newData: any = [...prevData]; // Create a copy of the state array
          newData[i] = {
            ...newData[i],
            status: response.success ? "VALID" : "INVALID",
          }; // Update the object at the specified index
          return newData; // Return the updated array to update the state
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleExportTableData = () => {
    if (gridRef.current && gridRef.current.exportDataToCSV) {
      // Call the child component's function
      const jsonData=gridRef.current.exportJSONData();
      uploadcredfilestoS3(jsonData)
    }
  };

  const handleSubmitCredentials = () => {
    if (hasCSVError) {
      messageApi.error({
        type: "error",
        content:
          "Invalid csv, mybe some filed does not have value( ie: username)",
      });
    } else {
      setSubmitted(true);
      let filteredItems = columnDefs.filter((item) => item.field !== "action");
      setColumnDefs([...filteredItems, statusColumn]);
      scrapperVerifyCredentials(tableData);
    }
  };

  const beforeUploadHandler = () => {
    return false; // Allow default action
  };

  const furtherProcessJSON = (jsonData: any) => {
    const keysToCheck = ["gstin", "username", "password"];

    const allObjectsContainKeysAndValues = jsonData.every((obj: any) =>
      keysToCheck.every((key) => obj.hasOwnProperty(key) && obj[key])
    );
    if (allObjectsContainKeysAndValues) {
      setHasCSVError(false);
      //   handleBulkUpload(jsonData);
      // setBulkData(jsonData);
      setTableData(jsonData);
    } else {
      setHasCSVError(true);
      messageApi.error({
        type: "error",
        content:
          "Invalid csv, mybe some filed does not have value( ie: username)",
      });
    }
  };

  const handleReadFileData = async (file: any) => {
    if (!file.originFileObj) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const text = e.target.result;
        const jsonArray = await csv().fromString(text);
        console.log("jsonArray", jsonArray);
        furtherProcessJSON(jsonArray);
      };

      reader.readAsText(file);
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: beforeUploadHandler,

    onChange(info) {
      const { status } = info.file;
      console.log("new file", info.file);
      handleReadFileData(info.file);
    },
  };


  const uploadcredfilestoS3 = async (jsonData:any) => {
    setLoading(true)
  const response = await apiPost(
      `${process.env.REACT_APP_API_URL}/storage/objects/upload/json?filename=${userDetails.clusterId}_${moment().unix()}.csv`,
      {
        data: jsonData,
      }
    );
    if (response.status) {
      messageApi.open({
        type: "success",
        content: "Credentials saved",
      });
      setLoading(false)
      props.goNext && props.goNext();

    } else {
      messageApi.error({
        type: "error",
        content: "Could not update credentials",
      });
    }
  };



  return (
    <div
      className="Greeting"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        className="TableContainer"
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
      >
        {!isSubmitted && props.tableData.type === "PAN" ? (
          <div
            style={{
              padding: "10px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {props.tableData.type === "PAN" ? (
              <>
                <div style={{ display: "flex" }}>
                  <Upload {...uploadProps}>
                    <Button
                      size="small"
                      type="primary"
                      style={{ marginRight: 24 }}
                      icon={<DownloadOutlined />}
                      shape="round"
                    >
                      Upload CSV
                    </Button>
                  </Upload>

                  <Button
                    size="small"
                    type="primary"
                    shape="round"
                    ghost
                    onClick={handleExportTableData}
                    icon={<ExportOutlined />}
                  >
                    Download CSV From Table
                  </Button>
                </div>
                <div>
                  <Button
                    shape="round"
                    size="small"
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={handleSubmitCredentials}
                  >
                    Submit Credentials
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        ) : null}

        {columnDefs.length > 0 ? (
          <div style={{ flex: 1, display: "flex" }}>
            <AgTableClient
              //@ts-ignore
              rowData={tableData}
              hideToolbar
              columnDefs={columnDefs}
              ref={gridRef}
          />
          </div>
        ) : null}
      </div>
      <FloatButton
        onClick={handleExportTableData}
        style={{ marginRight: 24, width: 200 }}
        shape="square"
        type="primary"
        
        icon={
          <div style={{ display: "flex", width: "100%" }}>
            <Typography style={{ color: "white", marginRight: 6 }}>
              {isLoading?"Saving....":"Notify Me Once Done"}
            </Typography>
            <RightCircleOutlined />
          </div>
        }
      />
      {contextHolder}
    </div>
  );
}
