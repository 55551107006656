import Dashboard from "app/scenes/Dashboard";
import MasterLayout from ".";
import Credentials from "app/scenes/Credentials";
import Reconcilation from "app/scenes/Reconcilation";
import ProtectedRoute from "app/utils/ProtectedRoute";
import credentialsRoutes from "app/scenes/Credentials/credentials.routes";
import reconcilationRoutes from "app/scenes/Reconcilation/reconcilation.routes";
import Invoices from "app/scenes/Invoices";
import Reports from "app/scenes/Reports";
// import Onboarding from "app/scenes/Onboarding";

export default {
  path: "",
  element: (
    <ProtectedRoute>
      <MasterLayout />
    </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "/dashboard",

      element: <Dashboard />,
      exact: true,
    },
    {
      path: "/invoices",

      element: <Invoices />,
      exact: true,
    },
    {
      path: "/reports",

      element: <Reports />,
      exact: true,
    },
    credentialsRoutes,
    reconcilationRoutes,

  ],
};
