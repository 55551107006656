import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
export default function Dashboard(props: any) {
  const [activeKey, setActiveKey] = useState(1);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);

  const getTabView = () => {
    let tabs: any = [];
    let id = 1;
    userDetails.dashboards.forEach((dashboard: any) => {
      tabs.push({
        title: dashboard.name,
        id: id,
        type: "VIEW",
        viewData: dashboard.link,
      });
      id = id + 1;
    });

    return tabs;
  };
  return (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <CustomReconTabs
              activeKey={activeKey}
              title="Report"
              onTabChange={(tabKey: any) => setActiveKey(tabKey)}
              onTabModify={(tabs: any) =>
                setTabView({ ...tabViews, dashboard: tabs })
              }
              hideAddTab
              hideRemove
              defaultTabs={getTabView()}
              width={200}
            />
          }
        />

        <div className="TableContainer">
          {(tabViews.dashboard || []).map((tab: any, key: any) => {
            return (
              <>
                <div
                  style={{
                    height: "100%",
                    display: activeKey === tab.id ? "flex" : "none",
                  }}
                  key={key}
                >
                  <iframe
                    height="100%"
                    style={{ width: "100%" }}
                    scrolling="no"
                    title="Iframe"
                    src={tab.viewData}
                  ></iframe>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
