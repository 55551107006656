import React, { useEffect, useState } from "react";

import { message, theme } from "antd";
import { Outlet, useNavigate } from "react-router";
import "./MasterLayout.scss";

import PrimaryNav from "../PrimaryNav";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import { apiGet } from "app/services/apiServices";
import Loader from "../Loader";
import AppLoader from "../AppLoader";

const MasterLayout = (props: any) => {
  const [expandedLayout, setExpandedLayout] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useRecoilState(userInfo);

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...response.data,
        currentWorkspace: response.data.workspaces[0],
        clusterId: response.data.cluster_id,
      });
      if (!response.data.hasOnboared) {
        navigate("/onboarding/welcome");
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <AppLoader />
    </div>
  ) : (
    <div className="MasterLayout">
      <div className="NavigationContainer">
        <PrimaryNav openMenu={() => setExpandedLayout(!expandedLayout)} />
      </div>
      <div
        className={
          "ContentLayout maxWidthLG " +
          (expandedLayout ? " " : " CollapsedLayout")
        }
      >
        <div className="AppContent">
          <Outlet />
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default MasterLayout;
