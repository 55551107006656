import "./PrimaryNav.scss";
import Typography from "../Typography";
import {
  LeftOutlined,
  UserAddOutlined,
  SettingOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: any = [
  getItem("Workspace Settings", "/settings/setting", <SettingOutlined />),

  getItem("Members", "/settings/members", <UserAddOutlined />),
  getItem("Access Control", "/settings/alc", <UserSwitchOutlined />),
];

export default function SubMenu(props: any) {
  const navigate = useNavigate();
  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click", e);
    navigate(e.key);
  };
  return (
    <div className="PrimaryNavSubMenu">
      <div className="WorkspaceInfoContainer">
        <div className="LeftContent">
          <div className="IconBox">
            <img
              src={
                "https://seeklogo.com/images/V/vistara-logo-C07710BC2B-seeklogo.com.png"
              }
              alt="WorkspaceLogo"
            />
          </div>
          <div className="WorkspaceInfo">
            <Typography weight={600}>Vistara</Typography>
            <Typography variant="xs" style={{ color: "rgba(0, 0, 0, 0.6)" }}>
              63 members
            </Typography>
          </div>
        </div>
        <div className="CollapseAction" onClick={props.onClose}>
          <LeftOutlined />
        </div>
      </div>
      <div className="MenuContainer">
        <div className="MenuCategory">
          <Menu
            onClick={onClick}
            style={{ width: 256 }}
            mode="vertical"
            items={items}
          />
        </div>
      </div>
    </div>
  );
}
