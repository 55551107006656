import { useEffect, useState } from "react";
import "./Onboarding.scss";
import { ConfigProvider, Steps } from "antd";
import Typography from "app/shared/Typography";
import Greeting from "./components/Greeting";
import { Outlet } from "react-router-dom";
import OnboardAirlineReconView from "./components/OnboardAirlineReconView";
import Tour, { TourProvider } from "@reactour/tour";
import OnboardConnectForm from "./components/OnboardConnectForm";
import OnboardUnReconciled from "./components/OnboardUnReconcilied";
import OnboardHotelReconvView from "./components/OnboardHotelReconvView";
import AddBusiness from "./components/AddBusiness";
import SitRelax from "./components/SitRelax";
import ValidateCredentials from "./components/ValidateCredentials";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
const stepsTypes = [
  "Hey! Welcome",
  "Reconcilation",
  "Booking Visualization",
  "Integration",
  "Here we go!",
];
export default function Onboarding(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [currentTourStep, setCurrentTourStep] = useState(0);
  const [disableNextTour, setDisableNextTour] = useState(false);
  const getStepItems = () => {
    let items = [];
    stepsTypes.forEach((element, key) => {
      items.push({
        title:
          activeStep == -1 ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="title"
                weight={700}
                style={{ color: "white" }}
              >
                0{key + 1}
              </Typography>{" "}
              <Typography
                variant="xs"
                style={{ color: "#e1e1e1", marginLeft: 4 }}
              >
                {element}
              </Typography>
            </div>
          ) : null,
      });
    });
    return items;
  };

  useEffect(() => {}, []);
  const addHighlighter = (domClassName, targetArrayId = null) => {
    let domToAppend = document.getElementsByClassName(domClassName);

    let selctedDom =
      domToAppend.length > 1
        ? domToAppend[targetArrayId ? targetArrayId : 0]
        : domToAppend;
    var div = document.createElement("div");
    div.className = "domHighlighterCircle";
    div.id = "domHighlighterCircle";

    // Create and append three ripple divs inside the new div
    for (var i = 0; i < 3; i++) {
      var rippleDiv = document.createElement("div");
      rippleDiv.className = "domHighlighterCircle-ripple";
      div.appendChild(rippleDiv);
    }

    selctedDom.appendChild(div);
  };

  const handleStepNext = (stepIndex) => {
    let nextStepInfo = steps[stepIndex];
    console.log("stepinfo", stepIndex, steps[stepIndex]);
      if(stepIndex>3){
            setActiveStep(2)
      }else{

    
    if (nextStepInfo.targetDom) {
      let targetDomSplit = nextStepInfo.targetDom.split("~");
      addHighlighter(targetDomSplit[0], targetDomSplit[1]);
      setDisableNextTour(true);
    }
    setCurrentTourStep(stepIndex);
  }
  };

  const renderForms = () => {
    switch (activeStep) {
      case 0:
        return <Greeting goNext={() => setActiveStep(activeStep + 1)} />;
      case 1:
        return (
          <TourProvider
            steps={steps}
            defaultOpen={true}
            currentStep={currentTourStep}
            styles={{
              popover: (base) => ({
                ...base,
                "--reactour-accent": "#ef5a3d",
                borderRadius: radius,
              }),
              maskArea: (base) => ({ ...base, rx: radius }),
              maskWrapper: (base) => ({ ...base, color: "#00000073" }),

              badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
              controls: (base) => ({ ...base, marginTop: 100 }),
              close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
            }}
            setCurrentStep={handleStepNext}
            disableDotsNavigation
            nextButton={({ currentStep, setCurrentStep, steps }) => {
              const first = currentStep === 0;
              return (
                <ArrowForwardRoundedIcon
                  style={{
                    fontSize: 20,
                    color: "#646464",
                    cursor: "pointer",
                    opacity: disableNextTour ? 0.5 : 1,
                  }}
                  onClick={() => {
                    !disableNextTour && setCurrentStep(currentStep + 1);
                  }}
                />
              );
            }}
          >
            <OnboardAirlineReconView
              goNext={() => setActiveStep(activeStep + 1)}
              onColGroup={(colData) => {
                console.log("columnData", colData);
                if (colData && colData.colId === "Vendor Name") {
                  setCurrentTourStep(currentTourStep + 1);
                  setDisableNextTour(false);
                }
              }}
            />
          </TourProvider>
        );
      case 2:
        return (
          <AddBusiness
            goNext={(jsonData) => {
              setActiveStep(activeStep + 1);
              setTableData(jsonData);
            }}
          />
        );
      case 3:
        return (
          <ValidateCredentials
            tableData={tableData}
            goNext={() => setActiveStep(activeStep + 1)}
          />
        );
      case 4:
        return <SitRelax goNext={() => setActiveStep(activeStep + 1)} />;
    }
  };

  const radius = 10;

  const steps = [
    {
      selector: ".ag-theme-alpine",
      content:
        "Table view for your Reconciliation data to visualize meaningful insights",
    },
    {
      selector: ".ag-column-drop-wrapper",
      content:
        "You can drag and drop your table columns in here to group them however you like",
    },
    {
      selector: ".ag-side-bar",
      content:
        "Sidebar enables you to perfome custom filter operations along data pivot mode.",
    },

    {
      selector: ".ag-header-cell-comp-wrapper",
      content:
        "Table column comes with features like filter when you hover or drag it to drop area for group",
    },
    // {
    //   selector: ".ag-theme-alpine",
    //   content:
    //     "Now, It's time to drag the Vendor Name column to group it and view insight",
    //   targetDom: "ag-header-cell~1",
    // },
    // {
    //   selector: ".ag-theme-alpine",
    //   content:
    //     "You can now click any of the grouped row to get summarized data",
    // },
  ];

  return (
    <div className="Onboarding">
      <div className="StepContainer">
        <ConfigProvider
          theme={{
            components: {
              Steps: {
                /* here is your component tokens */
              },
            },
          }}
        >
          <Steps
            current={activeStep}
            items={[
              {
                title: "Greeeting",
              },

              {
                title: "Reconcilation Tour",
              },
              {
                title: "Add Credentials",
              },
              {
                title: "Validate Credentials",
              },
              {
                title: "That's all",
              },
            ]}
          />
        </ConfigProvider>
      </div>
      <div className="ContentContainer">
        <div className="DetailContainer">{renderForms()}</div>
      </div>
    </div>
  );
}
