import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import { useEffect, useRef, useState } from "react";
import {
  API_ENDPOINT_AIRLINE_CREDENTIAL_CREATE,
  API_ENDPOINT_AIRLINE_CREDENTIAL_UPDATE,
  API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST,
} from "../../credentials.constants";
import Loader from "app/shared/Loader";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExportOutlined,
} from "@ant-design/icons";

import { Button, Empty, Spin, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import PageHeader from "app/shared/PageHeader";
export default function AirlineCredentials(props: any) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const gridRef = useRef<any>(null);
  const columnDefs = [
    {
      field: "workspace_name",
      headerName: "Workspace",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "pan",
      headerName: "PAN Number",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "airline_name",
      headerName: "Airline",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "portal_id",
      headerName: "Portal ID",
      enableRowGroup: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
      editable: true,
    },
    {
      field: "portal_pass",
      headerName: "Portal Password",
      enableRowGroup: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
      editable: true,
    },

    {
      field: "otp_email",
      headerName: "OTP Email",
      enableRowGroup: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
      editable: true,
    },
    {
      field: "otp_pass",
      headerName: "OTP Password",
      enableRowGroup: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
      editable: true,
    },

    {
      field: "code",
      headerName: "Code",
      enableRowGroup: true,
      editable: true,
    },
    {
      field: "valid",
      headerName: "Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "MATCH",
      formatProps: {
        matchLogic: {
          matchColor: {
            ACTIVE: "green",
            PENDING: "yellow",
            INVALID: "red",
          },
          icon: {
            true: <CheckCircleFilled />,
            false: <Spin />,
            null: <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },

    {
      field: "last_ran",
      headerName: "Last Ran",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "DATE",
      formatProps: {
        deaultText: "NA",
      },
    },
    {
      field: "action",
      headerName: "Action",
      enableRowGroup: true,
      editRow: true,
      focusKey: "portal_id",
      cellRenderer: AgTableEditButton,
      cellRendererParams: {
        onClick: (params: any) => console.log(params), // Define your edit logic here
      },
    },
  ];

  useEffect(() => {
    fetchAirlineredentials();
  }, [userDetails.currentWorkspace]);
  const fetchAirlineredentials = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST);
    if (response.status) {
      setTableData(response.data?.credentials);
    } else {
    }
    setLoading(false);
  };

  const updateAirlineCredential = async (rowPayload: any) => {
    const response = await apiPut(
      API_ENDPOINT_AIRLINE_CREDENTIAL_UPDATE,
      rowPayload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const createAirlineCredential = async (rowPayload: any) => {
    const response = await apiPost(
      API_ENDPOINT_AIRLINE_CREDENTIAL_CREATE,
      rowPayload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const handleRowEdit = (rowData: any) => {
    if (rowData.id) {
      updateAirlineCredential(rowData);
    } else {
      createAirlineCredential(rowData);
    }
  };

  const handleExportTableData = () => {
    console.log("calling export funciton", gridRef);

    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  return (
    <div className="ScreenContainer">
      <PageHeader
        title="Airline Credentials"
        rightActions={
          <div>
            <Button
              type="primary"
              size="small"
              icon={<ExportOutlined />}
              onClick={handleExportTableData}
              // style={{ marginRight: 12 }}
            >
              Export
            </Button>
          </div>
        }
      />
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={columnDefs}
            groupPanel
            onRowSaved={handleRowEdit}
            ref={gridRef}
            footer={false}
          />
        )}
      </div>

      {contextHolder}
    </div>
  );
}
