import Reconcilation from "./Reconcilation";
import AirlineReconcilation from "./components/AirlineReconcilation";
import GSTReconcilation from "./components/GSTReconcilation";

//eslint-disable-next-line
export default {
  path: "/reconcilation/",
  element: <Reconcilation />,
  strict: true,
  children: [
    {
      path: "/reconcilation/gst",
      element: <GSTReconcilation />,
      exact: true,
    },
    {
      path: "/reconcilation/airline",
      element: <AirlineReconcilation />,
      exact: true,
    },
  ],
};
