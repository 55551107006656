import { apiGet } from "app/services/apiServices";
import { AgTableServer } from "app/shared/AgTable";
import { useEffect, useRef, useState } from "react";
import {
  API_ENDPOINT_RECONCILATION_FETCH_AIRLINE_DATA,
  API_ENDPOINT_RECONCILATION_FETCH_GST_DATA,
} from "../../reconcilation.constants";
import Loader from "app/shared/Loader";
import { ExportOutlined, CloseOutlined } from "@ant-design/icons";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

import { Button, Tabs, Tag, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
import PageHeader from "app/shared/PageHeader";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import CustomReconTabs from "../CustomReconTabs";
function AirReconComponent(props: any) {
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const gridRef = useRef<any>(null);
  const openSidebarRenderer = (params: any) => {
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };

  const columnDefs = [
    {
      headerName: "Login Status",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Login Status",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "SSR Status",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "SSR Status",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Unique_Column",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Unique_Column",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Scraper type",
      columnGroupShow: "open",
      formatType: "CHIP",
      formatProps: {
        chipColor: "green",
      },
      filter: "agTextColumnFilter",
      field: "Scraper type",
      enableValue: true,
      enableRowGroup: true,
      minWidth: 240,
    },
    {
      headerName: "Email Access y/n",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Email Access y/n",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Workspace",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Workspace",
      enableValue: true,
      enableRowGroup: true,
      minWidth: 240,
    },
    {
      headerName: "Vendor",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Vendor",
      enableValue: true,
      enableRowGroup: true,
      minWidth: 240,
    },
    {
      headerName: "Transaction_Type",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Transaction_Type",
      enableValue: true,
      enableRowGroup: true,
      minWidth: 240,
    },
    {
      headerName: "Note Type",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Note Type",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Type",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Type",
      enableValue: true,
      enableRowGroup: true,
      minWidth: 240,
    },
    {
      headerName: "Financial_Year",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Financial_Year",
      enableValue: true,
      enableRowGroup: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice/Note_Number-Master",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice/Note_Number-Master",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Invoice/Note_Number",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Invoice/Note_Number",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Invoice/Note_Number",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-Invoice/Note_Number",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Status-2A/2B-Invoice/Note_Number",

      columnGroupShow: "open",
      formatType: "CHIP",
      formatProps: {
        chipColor: "green",
      },
      filter: "agTextColumnFilter",
      field: "Status-2A/2B-Invoice/Note_Number",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Original Invoice Number",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Original Invoice Number",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Original Invoice Date",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Original Invoice Date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Original Invoice Number",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-Original Invoice Number",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Original Invoice Date",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-Original Invoice Date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice/Note_Date-Master",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice/Note_Date-Master",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Invoice/Note_Date",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Invoice/Note_Date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Invoice/Note_Date",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-Invoice/Note_Date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Status-2A/2B-Invoice/Note_Date",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Status-2A/2B-Invoice/Note_Date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Supplier_GSTIN-Master",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Supplier_GSTIN-Master",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Supplier_GSTIN",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Supplier_GSTIN",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Supplier_GSTIN",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-Supplier_GSTIN",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Status-2A/2B-Supplier_GSTIN",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Status-2A/2B-Supplier_GSTIN",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Customer_GSTIN-Master",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Customer_GSTIN-Master",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Customer_GSTIN",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Customer_GSTIN",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Customer_GSTIN",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-Customer_GSTIN",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Status-2A/2B-Customer_GSTIN",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Status-2A/2B-Customer_GSTIN",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "HSN_SAC_Code",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "HSN_SAC_Code",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Tax_Rate-Master",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Tax_Rate-Master",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Tax_Rate",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Tax_Rate",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Tax_Rate",

      columnGroupShow: "open",

      formatType: "CHIP",
      formatProps: {
        chipColor: "green",
      },

      filter: "agTextColumnFilter",
      field: "Invoice-Tax_Rate",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Taxable",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Taxable",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Taxable",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-Taxable",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Taxable Difference",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Taxable Difference",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-CGST",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-CGST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-CGST",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-CGST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-SGST",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-SGST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-SGST",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-SGST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-IGST",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-IGST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-IGST",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-IGST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Total_GST-Master",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Total_GST-Master",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Total_GST",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Total_GST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Total_GST",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-Total_GST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "GST Difference",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "GST Difference",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A/2B-Total_Amount",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A/2B-Total_Amount",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice-Total_Amount",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice-Total_Amount",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Amount_Difference",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Amount_Difference",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Booking Status",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Booking Status",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "2A / 2B Status",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "2A / 2B Status",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "GSTR2- Status",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "GSTR2- Status",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Invoice Status",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Invoice Status",
      enableRowGroup: true,
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Claimed Status",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Claimed Status",
      enableValue: true,
      enableRowGroup: true,
      minWidth: 240,
    },
    {
      headerName: "Orginal Invoice Status",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Orginal Invoice Status",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Ticket/PNR",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Ticket/PNR",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Agency_Invoice_Number",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Agency_Invoice_Number",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Match Type",

      columnGroupShow: "open",

      filter: "agTextColumnFilter",
      field: "Match Type",
      enableValue: true,
      enableRowGroup: true,
      minWidth: 240,
    },
    {
      headerName: "Error 1-Inv. CGST not equal to SGST",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 1-Inv. CGST not equal to SGST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 1-2A/B. CGST not equal to SGST",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 1-2A/B. CGST not equal to SGST",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 2-Inv. If_IGST_Applied_then_CGST/SGST_should_be_blank",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 2-Inv. If_IGST_Applied_then_CGST/SGST_should_be_blank",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName:
        "Error 2-2A/B. If_IGST_Applied_then_CGST/SGST_should_be_blank",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 2-2A/B. If_IGST_Applied_then_CGST/SGST_should_be_blank",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 3-Inv. Correct_GST_Head",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 3-Inv. Correct_GST_Head",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 3-2A/B. Correct_GST_Head",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 3-2A/B. Correct_GST_Head",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 4-Inv. Customer_GSTIN_should_be_15_digits",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 4-Inv. Customer_GSTIN_should_be_15_digits",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 4-2A/B. Customer_GSTIN_should_be_15_digits",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 4-2A/B. Customer_GSTIN_should_be_15_digits",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 5-Inv. Supplier_GSTIN_should_be_15_digits",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 5-Inv. Supplier_GSTIN_should_be_15_digits",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 5-2A/B. Supplier_GSTIN_should_be_15_digits",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 5-2A/B. Supplier_GSTIN_should_be_15_digits",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName:
        "Error 6-Inv. Len_of_Invoice_Number_should_not_be_more_than_16_digits",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field:
        "Error 6-Inv. Len_of_Invoice_Number_should_not_be_more_than_16_digits",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName:
        "Error 6-2A/B. Len_of_Invoice_Number_should_not_be_more_than_16_digits",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field:
        "Error 6-2A/B. Len_of_Invoice_Number_should_not_be_more_than_16_digits",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 7-Inv. Invoice_Date_more_than_present_date",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 7-Inv. Invoice_Date_more_than_present_date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 7-2A/B. Invoice_Date_more_than_present_date",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 7-2A/B. Invoice_Date_more_than_present_date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 8-Inv. Amounts not matching with Type",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 8-Inv. Amounts not matching with Type",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 8-2A/B. Amounts not matching with Type",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 8-2A/B. Amounts not matching with Type",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 9-Inv. Existence_of_Invoice_Number",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 9-Inv. Existence_of_Invoice_Number",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 9-2A/B. Existence_of_Invoice_Number",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 9-2A/B. Existence_of_Invoice_Number",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 10-Inv. Existence_of_Invoice_Date",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 10-Inv. Existence_of_Invoice_Date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 10-2A/B. Existence_of_Invoice_Date",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 10-2A/B. Existence_of_Invoice_Date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 11-Inv. GST > Taxable",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 11-Inv. GST > Taxable",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 11-2A/B. GST > Taxable",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 11-2A/B. GST > Taxable",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName:
        "Error 12-Inv. Customer_GSTIN_should_not_be_same_as_Vendor_GSTIN",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 12-Inv. Customer_GSTIN_should_not_be_same_as_Vendor_GSTIN",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName:
        "Error 12-2A/B. Customer_GSTIN_should_not_be_same_as_Vendor_GSTIN",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 12-2A/B. Customer_GSTIN_should_not_be_same_as_Vendor_GSTIN",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 13. Supplier_GSTIN_not_matching_in_Invoice_and_2A/2B",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 13. Supplier_GSTIN_not_matching_in_Invoice_and_2A/2B",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName:
        "Error 13-C. Customer_GSTIN_not_matching_in_Invoice_and_2A/2B",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 13-C. Customer_GSTIN_not_matching_in_Invoice_and_2A/2B",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 14-Inv. Taxable_amount_cannot_exceed_Total_amount",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 14-Inv. Taxable_amount_cannot_exceed_Total_amount",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 14-2A/B. Taxable_amount_cannot_exceed_Total_amount",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 14-2A/B. Taxable_amount_cannot_exceed_Total_amount",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 15-Inv. Vendor is Blank",

      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 15-Inv. Vendor is Blank",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 15-2A/B. Vendor is Blank",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field: "Error 15-2A/B. Vendor is Blank",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 16-Inv. Invoice number ends with 0000",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field: "Error 16-Inv. Invoice number ends with 0000",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 16-2A/B. Invoice number ends with 0000",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field: "Error 16-2A/B. Invoice number ends with 0000",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 17-Inv. Invoice number contains + sign",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field: "Error 17-Inv. Invoice number contains + sign",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 17-ab. Invoice number contains + sign",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field: "Error 17-ab. Invoice number contains + sign",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName:
        "Error 18-Inv. Credit/Debit_Date_less_than_Original_Invoice_date",
      columnGroupShow: "open",

      filter: "agNumberColumnFilter",
      field: "Error 18-Inv. Credit/Debit_Date_less_than_Original_Invoice_date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName:
        "Error 18-2A/2B. Credit/Debit_Date_less_than_Original_Invoice_date",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field:
        "Error 18-2A/2B. Credit/Debit_Date_less_than_Original_Invoice_date",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 19-Inv. Customer_GSTIN is blank",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field: "Error 19-Inv. Customer_GSTIN is blank",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Error 19-ab. Customer_GSTIN is blank",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field: "Error 19-ab. Customer_GSTIN is blank",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "Total_Count_of_Errors",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field: "Total_Count_of_Errors",
      enableValue: true,
      minWidth: 240,
    },
    {
      headerName: "workspace_active",
      columnGroupShow: "open",
      filter: "agNumberColumnFilter",
      field: "workspace_active",
      enableValue: true,
      minWidth: 240,
    },

    {
      headerName: "Invoice Link",
      field: "Invoice_Link",
      cellRenderer: ToolParamInvoker,
      minWidth: 240,
      cellRendererParams: () => {
        return {
          label: "View Invoice",
          handleClick: openSidebarRenderer,
        };
      },
    },

    {
      headerName: "hash",
      filter: "agNumberColumnFilter",
      field: "hash",
      enableValue: true,
    },
  ];

  const handleExportTableData = () => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  return (
    <div className="TableContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <AgTableServer
          //@ts-ignore
          rowData={[]}
          columnDefs={columnDefs}
          endpoint={API_ENDPOINT_RECONCILATION_FETCH_AIRLINE_DATA}
          // endpoint={"http://192.168.1.59:7001/api/recon/hotel"}
          enablePDFViewer
          ref={gridRef}
        />
      )}
      {contextHolder}
    </div>
  );
}

export default function AirlineReconcilation(props: any) {
  const [activeKey, setActiveKey] = useState(1);
  const [totalTabs, setTotalTabs] = useState([
    {
      title: "Airline Recon",
      id: 1,
    },
  ]);
  return (
    <div className="ScreenContainer">
      <PageHeader
        leftActions={
          <CustomReconTabs
            activeKey={activeKey}
            title="Airline Recon"
            onTabChange={(tabKey: number) => setActiveKey(tabKey)}
            onTabModify={(tabs: any) => setTotalTabs(tabs)}
          />
        }
        rightActions={
          <div>
            <Button
              type="primary"
              size="small"
              icon={<ExportOutlined />}
              // onClick={handleExportTableData}
              // style={{ marginRight: 12 }}
            >
              Export
            </Button>
          </div>
        }
      />
      <div className="TabContainer">
        {totalTabs.map((tab: any, key: any) => {
          return (
            <div
              style={{
                height: "100%",
                display: activeKey === tab.id ? "flex" : "none",
              }}
              key={key}
            >
              <AirReconComponent />
            </div>
          );
        })}
      </div>
    </div>
  );
}
