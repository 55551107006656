import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import { useEffect, useRef, useState } from "react";
import {
  API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST,
  API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE,
  API_ENDPOINT_GST_CREDENTIAL_UPDATE,
} from "../../credentials.constants";
import Loader from "app/shared/Loader";
import { PlusOutlined, ExportOutlined } from "@ant-design/icons";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Empty, Modal, Spin, Tabs, Tag, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import BulkCreateGSTCreds from "./components/BulkGSTCredential";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import Typography from "app/shared/Typography";
import "../../Credentials.scss";
import PageHeader from "app/shared/PageHeader";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
export default function GSTCredentials(props: any) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const gridRef = useRef<any>(null);

  const columnDefs = [
    {
      field: "name",
      headerName: "Workspace",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "pan",
      headerName: "PAN",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "gstin",
      headerName: "GSTIN",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "username",
      headerName: "Username",
      enableRowGroup: true,
      minWidth: 200,
      editable: true,
    },

    {
      field: "password",
      headerName: "Password",
      enableRowGroup: true,
      minWidth: 200,
      editable: true,
    },

    {
      field: "status",
      headerName: "Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "MATCH",
      formatProps: {
        matchLogic: {
          matchColor: {
            ACTIVE: "green",
            PENDING: "yellow",
            INVALID: "red",
          },
          icon: {
            ACTIVE: <CheckCircleFilled />,
            PENDING: (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 12,
                      marginRight: 6,
                      color: "rgb(143 121 24)",
                    }}
                    spin
                  />
                }
              />
            ),
            INVALID: <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },

    {
      field: "sync",
      headerName: "Job Sync",
      enableRowGroup: true,
      minWidth: 200,

      cellRenderer: (params: any) => {
        return params?.data?.status === "PENDING" ||
          params?.data?.status === "INVALID" ? null : (
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <Tag
              color="blue"
              style={{ cursor: "pointer" }}
              // onClick={() => handleJobSync(params?.data)}
            >
              Sync Now
            </Tag>
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      enableRowGroup: true,
      editRow: true,
      focusKey: "username",
      cellRenderer: AgTableEditButton,
      cellRendererParams: {
        onClick: (params: any) => console.log(params), // Define your edit logic here
      },
    },
  ];

  useEffect(() => {
    fetchGSTCredentials();
  }, [userDetails.currentWorkspace]);
  const fetchGSTCredentials = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST);
    if (response.status) {
      setTableData(response.data?.credentials);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const updateGSTCredential = async (rowPayload: any) => {
    const response = await apiPut(API_ENDPOINT_GST_CREDENTIAL_UPDATE, {
      credentials: rowPayload,
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const createGSTCredential = async (rowPayload: any) => {
    const response = await apiPost(API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE, {
      credentials: [rowPayload],
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const handleRowEdit = (rowData: any) => {
    if (rowData.id) {
      updateGSTCredential(rowData);
    } else {
      createGSTCredential(rowData);
    }
  };

  const handleExportTableData = () => {
    console.log("calling export funciton", gridRef);

    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };
  return (
    <div className="ScreenContainer">
      <PageHeader
        title="GST Credentials"
        rightActions={
          <div>
            <Button
              type="primary"
              size="small"
              icon={<ExportOutlined />}
              onClick={handleExportTableData}
              style={{ marginRight: 12 }}
            >
              Export
            </Button>
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => setShowModal(true)}
            >
              Add Bulk Credentials
            </Button>
          </div>
        }
      />
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={columnDefs}
            groupPanel
            onRowSaved={handleRowEdit}
            ref={gridRef}
            footer={false}
          />
        )}
      </div>

      <Modal
        title="Create Bulk Credentials"
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        <div style={{ marginTop: 24 }}>
          <BulkCreateGSTCreds />
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}
