import ProtectedRoute from "app/utils/ProtectedRoute";
import Onboarding from "./Onboarding";
import Greeting from "./components/Greeting";

//eslint-disable-next-line
export default {
  path: "/onboarding/",
  element: (
    <ProtectedRoute>
      <Onboarding />
    </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "/onboarding/welcome",
      element: <Greeting />,
      exact: true,
    },
  ],
};
